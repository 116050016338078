<template>
  <banded-section
    title="Print Upload"
    :collapsable="false"
  >
    <template slot="tooltip">
      <div class="m-b-m">
        This section is visible to the Hypefactors team only.
      </div>

      <div class="m-b-m">
        Here you can upload Print files ...
      </div>
    </template>

    <div class="Print-Upload columns">
      <div class="column is-6 is-offset-3">
        <form enctype="multipart/form-data" @submit.prevent>
          <form-field
            :validator="$v.form.media_outlet"
            label="Media Title"
          >
            <clipping-media-outlets-picker
              v-model="form.media_outlet"
              type="print"
              :has-country="true"
              :has-publisher="true"
              :disabled="started"
            />
          </form-field>

          <form-field
            :label="$t('general.publication_date')"
            :validator="$v.form.publication_date"
          >
            <el-date-picker
              v-model="form.publication_date"
              placeholder="Publication date"
              type="date"
              name="publication_date"
              format="dd MMMM yyyy"
              value-format="yyyy-MM-dd"
              :disabled="started"
            />
          </form-field>

          <form-field :validator="$v.form.issue" label="Issue Number">
            <input
              v-model="form.issue"
              placeholder="Issue Number"
              type="text"
              name="issue"
              class="input"
              :disabled="started"
              @input="$v.form.issue.$touch"
            >
          </form-field>

          <form-field
            :validator="$v.form.pdf_file"
            label="PDF file"
          >
            <el-upload
              ref="uploader"
              :on-change="handleFileSelect"
              :on-remove="handleFileRemoval"
              :on-exceed="handleFileExceed"
              v-bind="fileUpload.options"
              class="m-b-m"
              :disabled="started"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text" v-html="$t('forms.drop_files_here')" />
              <div class="is-size-7 has-text-grey m-t-m">
                {{ $t('forms.accepted_file_types') }} {{ fileUpload.options.accept }}
              </div>
            </el-upload>

            <el-checkbox
              v-if="form.pdf_file"
              v-model="form.delete_existing"
              class="is-grey can-wrap-label"
            >
              Delete file if it was previously uploaded
            </el-checkbox>
          </form-field>

          <hr class="m-b-m">

          <div
            v-if="started"
            class="is-pulled-left"
          >
            Uploaded {{ totalUploaded }}%
          </div>

          <v-button
            :loading="started"
            type="button"
            class="is-primary is-pulled-right"
            @click.prevent="submit"
          >
            Upload
          </v-button>
        </form>
      </div>
    </div>
  </banded-section>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import * as UpChunk from '@mux/upchunk'
import { mapGetters } from 'vuex'

import Form from '@/services/forms/Form'
import ClippingMediaOutletsPicker from '@/components/clippings/ClippingMediaOutletsPicker'

export default {
  components: {
    ClippingMediaOutletsPicker
  },

  data () {
    return {
      form: new Form({
        media_outlet: { value: null, rules: { required } },
        publication_date: { value: null, rules: { required } },
        issue: { value: null, rules: { } },
        pdf_file: { value: null, rules: { required } },
        delete_existing: { value: null, rules: { } }
      }),
      started: false,
      totalUploaded: 0
    }
  },

  computed: {
    ...mapGetters(['authToken']),

    fileUpload () {
      return {
        options: {
          limit: 1,
          multiple: false,
          autoUpload: false,
          accept: 'application/pdf',
          action: '',
          drag: true
        }
      }
    }
  },

  validations () {
    return {
      form: this.form.rules()
    }
  },

  methods: {
    handleFileSelect (file) {
      if (file) {
        this.form.pdf_file = file.raw
      }
    },

    handleFileRemoval () {
      this.form.pdf_file = null
    },

    handleFileExceed () {
      this.$alert('You can only upload one file at a time. Please remove the old one first.')
    },

    async upload () {
      const payload = {
        'media_outlet_id': this.form.media_outlet,
        'publication_date': this.form.publication_date,
        'issue': this.form.issue || '',
        'delete_existing': this.form.delete_existing
      }

      await this.$api.post('/staff/print-upload/start', payload).then((response) => {
        this.started = true

        const uploader = UpChunk.createUpload({
          endpoint: response.data.url,
          file: this.form.pdf_file,
          chunkSize: 25600, // File in ~25mb chunks
          headers: {
            Authorization: `Bearer ${this.authToken}`,
            'Access-Control-Allow-Origin': '*',
            'X-Signed-URL': response.data.signed_url
          }
        })

        uploader.on('progress', progress => {
          this.totalUploaded = Math.round(progress.detail)
        })

        uploader.on('success', async () => {
          const payload = {
            'media_outlet_id': this.form.media_outlet,
            'publication_date': this.form.publication_date,
            'issue': this.form.issue || '',
            'path': response.data.path
          }

          await this.$api.post('/staff/print-upload/complete', payload)
            .then(response => {
              this.clearForm()

              this.$alert(`File uploaded successfully.<br /><br /><strong>Hypefactors Internal File ID:</strong><br />${response.data.object_id}`, 'Print Upload', {
                dangerouslyUseHTMLString: true
              })
            })
        })
      }).catch(err => {
        this.$alert(err.response.data.message)
      })
    },

    clearForm () {
      this.form.reset()
      this.$v.form.$reset()
      this.$refs.uploader.clearFiles()
      this.started = false
      this.totalUploaded = 0
    },

    async submit () {
      this.$v.form.$touch()

      if (this.$v.form.$error) {
        return
      }

      try {
        await this.upload()
      } catch (err) {
        this.$displayRequestError(err)
      }
    }
  }
}
</script>

<style lang='scss'>
@import '~utils';

.Print-Upload {
  .el-upload, .el-upload-dragger {
    width: 100%;
  }

  .result {
    padding: $padding-small $padding;

    &.warn {
      border-left: 4px solid $hf__color-warning;
      background: rgba($hf__color-warning, 0.1);
    }

    &.error {
      border-left: 4px solid $hf__color-danger;
      background: rgba($hf__color-danger, 0.1);
    }

    &.info {
      border-left: 4px solid $hf__color-info;
      background: rgba($hf__color-info, 0.1);
    }
  }
}
</style>
